"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Appointment API
 * API for appointment management
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServiceTypeApi = exports.ServiceTypeApiFactory = exports.ServiceTypeApiFp = exports.ServiceTypeApiAxiosParamCreator = exports.ServiceScheduleAvailabilityApi = exports.ServiceScheduleAvailabilityApiFactory = exports.ServiceScheduleAvailabilityApiFp = exports.ServiceScheduleAvailabilityApiAxiosParamCreator = exports.ServiceScheduleApi = exports.ServiceScheduleApiFactory = exports.ServiceScheduleApiFp = exports.ServiceScheduleApiAxiosParamCreator = exports.ServiceBookingsApi = exports.ServiceBookingsApiFactory = exports.ServiceBookingsApiFp = exports.ServiceBookingsApiAxiosParamCreator = exports.ServiceApi = exports.ServiceApiFactory = exports.ServiceApiFp = exports.ServiceApiAxiosParamCreator = exports.DefaultApi = exports.DefaultApiFactory = exports.DefaultApiFp = exports.DefaultApiAxiosParamCreator = exports.SortOrder = exports.BookingStatus = exports.AppointmentTypeRecurrenceFrequency = exports.AppointmentTypePeriodType = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
/**
 *
 * @export
 * @enum {string}
 */
exports.AppointmentTypePeriodType = {
    Unlimited: 'UNLIMITED',
    Rolling: 'ROLLING',
    Range: 'RANGE'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.AppointmentTypeRecurrenceFrequency = {
    _0: '0',
    _1: '1',
    _2: '2'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.BookingStatus = {
    Cancelled: 'CANCELLED',
    Accepted: 'ACCEPTED',
    Rejected: 'REJECTED',
    Pending: 'PENDING'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.SortOrder = {
    Asc: 'ASC',
    Desc: 'DESC'
};
/**
 * DefaultApi - axios parameter creator
 * @export
 */
var DefaultApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetHealthCheck: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/healthCheck";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Returns HTTP 200 if all the integrations are functional, HTTP 503 otherwise
         * @summary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsGetIntegrationCheck: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/integrations/healthCheck";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
exports.DefaultApiAxiosParamCreator = DefaultApiAxiosParamCreator;
/**
 * DefaultApi - functional programming interface
 * @export
 */
var DefaultApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.DefaultApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetHealthCheck: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.appGetHealthCheck(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Returns HTTP 200 if all the integrations are functional, HTTP 503 otherwise
         * @summary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsGetIntegrationCheck: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.integrationsGetIntegrationCheck(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.DefaultApiFp = DefaultApiFp;
/**
 * DefaultApi - factory interface
 * @export
 */
var DefaultApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.DefaultApiFp)(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appGetHealthCheck: function (options) {
            return localVarFp.appGetHealthCheck(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Returns HTTP 200 if all the integrations are functional, HTTP 503 otherwise
         * @summary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationsGetIntegrationCheck: function (options) {
            return localVarFp.integrationsGetIntegrationCheck(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.DefaultApiFactory = DefaultApiFactory;
/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
var DefaultApi = /** @class */ (function (_super) {
    __extends(DefaultApi, _super);
    function DefaultApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.appGetHealthCheck = function (options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).appGetHealthCheck(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Returns HTTP 200 if all the integrations are functional, HTTP 503 otherwise
     * @summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.integrationsGetIntegrationCheck = function (options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).integrationsGetIntegrationCheck(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return DefaultApi;
}(base_1.BaseAPI));
exports.DefaultApi = DefaultApi;
/**
 * ServiceApi - axios parameter creator
 * @export
 */
var ServiceApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {CreateAppointmentTypeDto} createAppointmentTypeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentTypeCreate: function (pharmacyId, locationId, createAppointmentTypeDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentTypeCreate', 'pharmacyId', pharmacyId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentTypeCreate', 'locationId', locationId);
                    // verify required parameter 'createAppointmentTypeDto' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentTypeCreate', 'createAppointmentTypeDto', createAppointmentTypeDto);
                    localVarPath = "/pharmacies/{pharmacy_id}/locations/{location_id}/appointment-types"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)))
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createAppointmentTypeDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentTypeFind: function (pharmacyId, locationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentTypeFind', 'pharmacyId', pharmacyId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentTypeFind', 'locationId', locationId);
                    localVarPath = "/pharmacies/{pharmacy_id}/locations/{location_id}/appointment-types"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)))
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentTypeFindOne: function (pharmacyId, locationId, id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentTypeFindOne', 'pharmacyId', pharmacyId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentTypeFindOne', 'locationId', locationId);
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentTypeFindOne', 'id', id);
                    localVarPath = "/pharmacies/{pharmacy_id}/locations/{location_id}/appointment-types/{id}"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)))
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} id
         * @param {string} startDate
         * @param {string} endDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentTypeGetAvailableSlots: function (pharmacyId, locationId, id, startDate, endDate, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentTypeGetAvailableSlots', 'pharmacyId', pharmacyId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentTypeGetAvailableSlots', 'locationId', locationId);
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentTypeGetAvailableSlots', 'id', id);
                    // verify required parameter 'startDate' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentTypeGetAvailableSlots', 'startDate', startDate);
                    // verify required parameter 'endDate' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentTypeGetAvailableSlots', 'endDate', endDate);
                    localVarPath = "/pharmacies/{pharmacy_id}/locations/{location_id}/appointment-types/{id}/available-slots"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)))
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (startDate !== undefined) {
                        localVarQueryParameter['startDate'] = (startDate instanceof Date) ?
                            startDate.toISOString() :
                            startDate;
                    }
                    if (endDate !== undefined) {
                        localVarQueryParameter['endDate'] = (endDate instanceof Date) ?
                            endDate.toISOString() :
                            endDate;
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentTypeRemove: function (pharmacyId, locationId, id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentTypeRemove', 'pharmacyId', pharmacyId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentTypeRemove', 'locationId', locationId);
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentTypeRemove', 'id', id);
                    localVarPath = "/pharmacies/{pharmacy_id}/locations/{location_id}/appointment-types/{id}"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)))
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} id
         * @param {UpdateAppointmentTypeDto} updateAppointmentTypeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentTypeUpdate: function (pharmacyId, locationId, id, updateAppointmentTypeDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentTypeUpdate', 'pharmacyId', pharmacyId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentTypeUpdate', 'locationId', locationId);
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentTypeUpdate', 'id', id);
                    // verify required parameter 'updateAppointmentTypeDto' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentTypeUpdate', 'updateAppointmentTypeDto', updateAppointmentTypeDto);
                    localVarPath = "/pharmacies/{pharmacy_id}/locations/{location_id}/appointment-types/{id}"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)))
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updateAppointmentTypeDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
exports.ServiceApiAxiosParamCreator = ServiceApiAxiosParamCreator;
/**
 * ServiceApi - functional programming interface
 * @export
 */
var ServiceApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.ServiceApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {CreateAppointmentTypeDto} createAppointmentTypeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentTypeCreate: function (pharmacyId, locationId, createAppointmentTypeDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.appointmentTypeCreate(pharmacyId, locationId, createAppointmentTypeDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentTypeFind: function (pharmacyId, locationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.appointmentTypeFind(pharmacyId, locationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentTypeFindOne: function (pharmacyId, locationId, id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.appointmentTypeFindOne(pharmacyId, locationId, id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} id
         * @param {string} startDate
         * @param {string} endDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentTypeGetAvailableSlots: function (pharmacyId, locationId, id, startDate, endDate, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.appointmentTypeGetAvailableSlots(pharmacyId, locationId, id, startDate, endDate, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentTypeRemove: function (pharmacyId, locationId, id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.appointmentTypeRemove(pharmacyId, locationId, id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} id
         * @param {UpdateAppointmentTypeDto} updateAppointmentTypeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentTypeUpdate: function (pharmacyId, locationId, id, updateAppointmentTypeDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.appointmentTypeUpdate(pharmacyId, locationId, id, updateAppointmentTypeDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.ServiceApiFp = ServiceApiFp;
/**
 * ServiceApi - factory interface
 * @export
 */
var ServiceApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.ServiceApiFp)(configuration);
    return {
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {CreateAppointmentTypeDto} createAppointmentTypeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentTypeCreate: function (pharmacyId, locationId, createAppointmentTypeDto, options) {
            return localVarFp.appointmentTypeCreate(pharmacyId, locationId, createAppointmentTypeDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentTypeFind: function (pharmacyId, locationId, options) {
            return localVarFp.appointmentTypeFind(pharmacyId, locationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentTypeFindOne: function (pharmacyId, locationId, id, options) {
            return localVarFp.appointmentTypeFindOne(pharmacyId, locationId, id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} id
         * @param {string} startDate
         * @param {string} endDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentTypeGetAvailableSlots: function (pharmacyId, locationId, id, startDate, endDate, options) {
            return localVarFp.appointmentTypeGetAvailableSlots(pharmacyId, locationId, id, startDate, endDate, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentTypeRemove: function (pharmacyId, locationId, id, options) {
            return localVarFp.appointmentTypeRemove(pharmacyId, locationId, id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} id
         * @param {UpdateAppointmentTypeDto} updateAppointmentTypeDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentTypeUpdate: function (pharmacyId, locationId, id, updateAppointmentTypeDto, options) {
            return localVarFp.appointmentTypeUpdate(pharmacyId, locationId, id, updateAppointmentTypeDto, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.ServiceApiFactory = ServiceApiFactory;
/**
 * ServiceApi - object-oriented interface
 * @export
 * @class ServiceApi
 * @extends {BaseAPI}
 */
var ServiceApi = /** @class */ (function (_super) {
    __extends(ServiceApi, _super);
    function ServiceApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {string} pharmacyId
     * @param {string} locationId
     * @param {CreateAppointmentTypeDto} createAppointmentTypeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    ServiceApi.prototype.appointmentTypeCreate = function (pharmacyId, locationId, createAppointmentTypeDto, options) {
        var _this = this;
        return (0, exports.ServiceApiFp)(this.configuration).appointmentTypeCreate(pharmacyId, locationId, createAppointmentTypeDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {string} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    ServiceApi.prototype.appointmentTypeFind = function (pharmacyId, locationId, options) {
        var _this = this;
        return (0, exports.ServiceApiFp)(this.configuration).appointmentTypeFind(pharmacyId, locationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {string} locationId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    ServiceApi.prototype.appointmentTypeFindOne = function (pharmacyId, locationId, id, options) {
        var _this = this;
        return (0, exports.ServiceApiFp)(this.configuration).appointmentTypeFindOne(pharmacyId, locationId, id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {string} locationId
     * @param {string} id
     * @param {string} startDate
     * @param {string} endDate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    ServiceApi.prototype.appointmentTypeGetAvailableSlots = function (pharmacyId, locationId, id, startDate, endDate, options) {
        var _this = this;
        return (0, exports.ServiceApiFp)(this.configuration).appointmentTypeGetAvailableSlots(pharmacyId, locationId, id, startDate, endDate, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {string} locationId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    ServiceApi.prototype.appointmentTypeRemove = function (pharmacyId, locationId, id, options) {
        var _this = this;
        return (0, exports.ServiceApiFp)(this.configuration).appointmentTypeRemove(pharmacyId, locationId, id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {string} locationId
     * @param {string} id
     * @param {UpdateAppointmentTypeDto} updateAppointmentTypeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceApi
     */
    ServiceApi.prototype.appointmentTypeUpdate = function (pharmacyId, locationId, id, updateAppointmentTypeDto, options) {
        var _this = this;
        return (0, exports.ServiceApiFp)(this.configuration).appointmentTypeUpdate(pharmacyId, locationId, id, updateAppointmentTypeDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ServiceApi;
}(base_1.BaseAPI));
exports.ServiceApi = ServiceApi;
/**
 * ServiceBookingsApi - axios parameter creator
 * @export
 */
var ServiceBookingsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingCancel: function (pharmacyId, locationId, id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('bookingCancel', 'pharmacyId', pharmacyId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('bookingCancel', 'locationId', locationId);
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('bookingCancel', 'id', id);
                    localVarPath = "/pharmacies/{pharmacy_id}/locations/{location_id}/bookings/{id}"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)))
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {CreateBookingDto} createBookingDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingCreate: function (pharmacyId, locationId, createBookingDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('bookingCreate', 'pharmacyId', pharmacyId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('bookingCreate', 'locationId', locationId);
                    // verify required parameter 'createBookingDto' is not null or undefined
                    (0, common_1.assertParamExists)('bookingCreate', 'createBookingDto', createBookingDto);
                    localVarPath = "/pharmacies/{pharmacy_id}/locations/{location_id}/bookings"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)))
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createBookingDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {number} offset
         * @param {number} limit
         * @param {SortOrder} orderBy
         * @param {string} [locationId]
         * @param {string} [appointmentTypeId]
         * @param {string} [patientUserId]
         * @param {string} [minStartDate]
         * @param {string} [maxStartDate]
         * @param {string} [minEndDate]
         * @param {string} [maxEndDate]
         * @param {BookingStatus} [status] CANCELLED, ACCEPTED, REJECTED, PENDING
         * @param {boolean} [withAllCanceled]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingFindFind: function (pharmacyId, offset, limit, orderBy, locationId, appointmentTypeId, patientUserId, minStartDate, maxStartDate, minEndDate, maxEndDate, status, withAllCanceled, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('bookingFindFind', 'pharmacyId', pharmacyId);
                    // verify required parameter 'offset' is not null or undefined
                    (0, common_1.assertParamExists)('bookingFindFind', 'offset', offset);
                    // verify required parameter 'limit' is not null or undefined
                    (0, common_1.assertParamExists)('bookingFindFind', 'limit', limit);
                    // verify required parameter 'orderBy' is not null or undefined
                    (0, common_1.assertParamExists)('bookingFindFind', 'orderBy', orderBy);
                    localVarPath = "/pharmacies/{pharmacy_id}/bookings"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (offset !== undefined) {
                        localVarQueryParameter['offset'] = offset;
                    }
                    if (limit !== undefined) {
                        localVarQueryParameter['limit'] = limit;
                    }
                    if (locationId !== undefined) {
                        localVarQueryParameter['location_id'] = locationId;
                    }
                    if (appointmentTypeId !== undefined) {
                        localVarQueryParameter['appointment_type_id'] = appointmentTypeId;
                    }
                    if (patientUserId !== undefined) {
                        localVarQueryParameter['patient_user_id'] = patientUserId;
                    }
                    if (minStartDate !== undefined) {
                        localVarQueryParameter['min_start_date'] = minStartDate;
                    }
                    if (maxStartDate !== undefined) {
                        localVarQueryParameter['max_start_date'] = maxStartDate;
                    }
                    if (minEndDate !== undefined) {
                        localVarQueryParameter['min_end_date'] = minEndDate;
                    }
                    if (maxEndDate !== undefined) {
                        localVarQueryParameter['max_end_date'] = maxEndDate;
                    }
                    if (status !== undefined) {
                        localVarQueryParameter['status'] = status;
                    }
                    if (withAllCanceled !== undefined) {
                        localVarQueryParameter['with_all_canceled'] = withAllCanceled;
                    }
                    if (orderBy !== undefined) {
                        localVarQueryParameter['order_by'] = orderBy;
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingFindOne: function (pharmacyId, locationId, id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('bookingFindOne', 'pharmacyId', pharmacyId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('bookingFindOne', 'locationId', locationId);
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('bookingFindOne', 'id', id);
                    localVarPath = "/pharmacies/{pharmacy_id}/locations/{location_id}/bookings/{id}"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)))
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} id
         * @param {UpdateBookingDto} updateBookingDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingUpdate: function (pharmacyId, locationId, id, updateBookingDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('bookingUpdate', 'pharmacyId', pharmacyId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('bookingUpdate', 'locationId', locationId);
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('bookingUpdate', 'id', id);
                    // verify required parameter 'updateBookingDto' is not null or undefined
                    (0, common_1.assertParamExists)('bookingUpdate', 'updateBookingDto', updateBookingDto);
                    localVarPath = "/pharmacies/{pharmacy_id}/locations/{location_id}/bookings/{id}"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)))
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updateBookingDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
exports.ServiceBookingsApiAxiosParamCreator = ServiceBookingsApiAxiosParamCreator;
/**
 * ServiceBookingsApi - functional programming interface
 * @export
 */
var ServiceBookingsApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.ServiceBookingsApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingCancel: function (pharmacyId, locationId, id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.bookingCancel(pharmacyId, locationId, id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {CreateBookingDto} createBookingDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingCreate: function (pharmacyId, locationId, createBookingDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.bookingCreate(pharmacyId, locationId, createBookingDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {number} offset
         * @param {number} limit
         * @param {SortOrder} orderBy
         * @param {string} [locationId]
         * @param {string} [appointmentTypeId]
         * @param {string} [patientUserId]
         * @param {string} [minStartDate]
         * @param {string} [maxStartDate]
         * @param {string} [minEndDate]
         * @param {string} [maxEndDate]
         * @param {BookingStatus} [status] CANCELLED, ACCEPTED, REJECTED, PENDING
         * @param {boolean} [withAllCanceled]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingFindFind: function (pharmacyId, offset, limit, orderBy, locationId, appointmentTypeId, patientUserId, minStartDate, maxStartDate, minEndDate, maxEndDate, status, withAllCanceled, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.bookingFindFind(pharmacyId, offset, limit, orderBy, locationId, appointmentTypeId, patientUserId, minStartDate, maxStartDate, minEndDate, maxEndDate, status, withAllCanceled, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingFindOne: function (pharmacyId, locationId, id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.bookingFindOne(pharmacyId, locationId, id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} id
         * @param {UpdateBookingDto} updateBookingDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingUpdate: function (pharmacyId, locationId, id, updateBookingDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.bookingUpdate(pharmacyId, locationId, id, updateBookingDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.ServiceBookingsApiFp = ServiceBookingsApiFp;
/**
 * ServiceBookingsApi - factory interface
 * @export
 */
var ServiceBookingsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.ServiceBookingsApiFp)(configuration);
    return {
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingCancel: function (pharmacyId, locationId, id, options) {
            return localVarFp.bookingCancel(pharmacyId, locationId, id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {CreateBookingDto} createBookingDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingCreate: function (pharmacyId, locationId, createBookingDto, options) {
            return localVarFp.bookingCreate(pharmacyId, locationId, createBookingDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {number} offset
         * @param {number} limit
         * @param {SortOrder} orderBy
         * @param {string} [locationId]
         * @param {string} [appointmentTypeId]
         * @param {string} [patientUserId]
         * @param {string} [minStartDate]
         * @param {string} [maxStartDate]
         * @param {string} [minEndDate]
         * @param {string} [maxEndDate]
         * @param {BookingStatus} [status] CANCELLED, ACCEPTED, REJECTED, PENDING
         * @param {boolean} [withAllCanceled]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingFindFind: function (pharmacyId, offset, limit, orderBy, locationId, appointmentTypeId, patientUserId, minStartDate, maxStartDate, minEndDate, maxEndDate, status, withAllCanceled, options) {
            return localVarFp.bookingFindFind(pharmacyId, offset, limit, orderBy, locationId, appointmentTypeId, patientUserId, minStartDate, maxStartDate, minEndDate, maxEndDate, status, withAllCanceled, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingFindOne: function (pharmacyId, locationId, id, options) {
            return localVarFp.bookingFindOne(pharmacyId, locationId, id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} id
         * @param {UpdateBookingDto} updateBookingDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingUpdate: function (pharmacyId, locationId, id, updateBookingDto, options) {
            return localVarFp.bookingUpdate(pharmacyId, locationId, id, updateBookingDto, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.ServiceBookingsApiFactory = ServiceBookingsApiFactory;
/**
 * ServiceBookingsApi - object-oriented interface
 * @export
 * @class ServiceBookingsApi
 * @extends {BaseAPI}
 */
var ServiceBookingsApi = /** @class */ (function (_super) {
    __extends(ServiceBookingsApi, _super);
    function ServiceBookingsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {string} pharmacyId
     * @param {string} locationId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceBookingsApi
     */
    ServiceBookingsApi.prototype.bookingCancel = function (pharmacyId, locationId, id, options) {
        var _this = this;
        return (0, exports.ServiceBookingsApiFp)(this.configuration).bookingCancel(pharmacyId, locationId, id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {string} locationId
     * @param {CreateBookingDto} createBookingDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceBookingsApi
     */
    ServiceBookingsApi.prototype.bookingCreate = function (pharmacyId, locationId, createBookingDto, options) {
        var _this = this;
        return (0, exports.ServiceBookingsApiFp)(this.configuration).bookingCreate(pharmacyId, locationId, createBookingDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {number} offset
     * @param {number} limit
     * @param {SortOrder} orderBy
     * @param {string} [locationId]
     * @param {string} [appointmentTypeId]
     * @param {string} [patientUserId]
     * @param {string} [minStartDate]
     * @param {string} [maxStartDate]
     * @param {string} [minEndDate]
     * @param {string} [maxEndDate]
     * @param {BookingStatus} [status] CANCELLED, ACCEPTED, REJECTED, PENDING
     * @param {boolean} [withAllCanceled]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceBookingsApi
     */
    ServiceBookingsApi.prototype.bookingFindFind = function (pharmacyId, offset, limit, orderBy, locationId, appointmentTypeId, patientUserId, minStartDate, maxStartDate, minEndDate, maxEndDate, status, withAllCanceled, options) {
        var _this = this;
        return (0, exports.ServiceBookingsApiFp)(this.configuration).bookingFindFind(pharmacyId, offset, limit, orderBy, locationId, appointmentTypeId, patientUserId, minStartDate, maxStartDate, minEndDate, maxEndDate, status, withAllCanceled, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {string} locationId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceBookingsApi
     */
    ServiceBookingsApi.prototype.bookingFindOne = function (pharmacyId, locationId, id, options) {
        var _this = this;
        return (0, exports.ServiceBookingsApiFp)(this.configuration).bookingFindOne(pharmacyId, locationId, id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {string} locationId
     * @param {string} id
     * @param {UpdateBookingDto} updateBookingDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceBookingsApi
     */
    ServiceBookingsApi.prototype.bookingUpdate = function (pharmacyId, locationId, id, updateBookingDto, options) {
        var _this = this;
        return (0, exports.ServiceBookingsApiFp)(this.configuration).bookingUpdate(pharmacyId, locationId, id, updateBookingDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ServiceBookingsApi;
}(base_1.BaseAPI));
exports.ServiceBookingsApi = ServiceBookingsApi;
/**
 * ServiceScheduleApi - axios parameter creator
 * @export
 */
var ServiceScheduleApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {CreateAppointmentTypeScheduleDto} createAppointmentTypeScheduleDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentTypeScheduleCreate: function (pharmacyId, locationId, createAppointmentTypeScheduleDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentTypeScheduleCreate', 'pharmacyId', pharmacyId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentTypeScheduleCreate', 'locationId', locationId);
                    // verify required parameter 'createAppointmentTypeScheduleDto' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentTypeScheduleCreate', 'createAppointmentTypeScheduleDto', createAppointmentTypeScheduleDto);
                    localVarPath = "/pharmacies/{pharmacy_id}/locations/{location_id}/appointment-type-schedules"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)))
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createAppointmentTypeScheduleDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentTypeScheduleFind: function (pharmacyId, locationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentTypeScheduleFind', 'pharmacyId', pharmacyId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentTypeScheduleFind', 'locationId', locationId);
                    localVarPath = "/pharmacies/{pharmacy_id}/locations/{location_id}/appointment-type-schedules"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)))
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentTypeScheduleFindOne: function (pharmacyId, locationId, id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentTypeScheduleFindOne', 'pharmacyId', pharmacyId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentTypeScheduleFindOne', 'locationId', locationId);
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentTypeScheduleFindOne', 'id', id);
                    localVarPath = "/pharmacies/{pharmacy_id}/locations/{location_id}/appointment-type-schedules/{id}"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)))
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentTypeScheduleRemove: function (pharmacyId, locationId, id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentTypeScheduleRemove', 'pharmacyId', pharmacyId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentTypeScheduleRemove', 'locationId', locationId);
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentTypeScheduleRemove', 'id', id);
                    localVarPath = "/pharmacies/{pharmacy_id}/locations/{location_id}/appointment-type-schedules/{id}"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)))
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} id
         * @param {UpdateAppointmentTypeScheduleDto} updateAppointmentTypeScheduleDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentTypeScheduleUpdate: function (pharmacyId, locationId, id, updateAppointmentTypeScheduleDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentTypeScheduleUpdate', 'pharmacyId', pharmacyId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentTypeScheduleUpdate', 'locationId', locationId);
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentTypeScheduleUpdate', 'id', id);
                    // verify required parameter 'updateAppointmentTypeScheduleDto' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentTypeScheduleUpdate', 'updateAppointmentTypeScheduleDto', updateAppointmentTypeScheduleDto);
                    localVarPath = "/pharmacies/{pharmacy_id}/locations/{location_id}/appointment-type-schedules/{id}"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)))
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updateAppointmentTypeScheduleDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
exports.ServiceScheduleApiAxiosParamCreator = ServiceScheduleApiAxiosParamCreator;
/**
 * ServiceScheduleApi - functional programming interface
 * @export
 */
var ServiceScheduleApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.ServiceScheduleApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {CreateAppointmentTypeScheduleDto} createAppointmentTypeScheduleDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentTypeScheduleCreate: function (pharmacyId, locationId, createAppointmentTypeScheduleDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.appointmentTypeScheduleCreate(pharmacyId, locationId, createAppointmentTypeScheduleDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentTypeScheduleFind: function (pharmacyId, locationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.appointmentTypeScheduleFind(pharmacyId, locationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentTypeScheduleFindOne: function (pharmacyId, locationId, id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.appointmentTypeScheduleFindOne(pharmacyId, locationId, id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentTypeScheduleRemove: function (pharmacyId, locationId, id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.appointmentTypeScheduleRemove(pharmacyId, locationId, id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} id
         * @param {UpdateAppointmentTypeScheduleDto} updateAppointmentTypeScheduleDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentTypeScheduleUpdate: function (pharmacyId, locationId, id, updateAppointmentTypeScheduleDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.appointmentTypeScheduleUpdate(pharmacyId, locationId, id, updateAppointmentTypeScheduleDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.ServiceScheduleApiFp = ServiceScheduleApiFp;
/**
 * ServiceScheduleApi - factory interface
 * @export
 */
var ServiceScheduleApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.ServiceScheduleApiFp)(configuration);
    return {
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {CreateAppointmentTypeScheduleDto} createAppointmentTypeScheduleDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentTypeScheduleCreate: function (pharmacyId, locationId, createAppointmentTypeScheduleDto, options) {
            return localVarFp.appointmentTypeScheduleCreate(pharmacyId, locationId, createAppointmentTypeScheduleDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentTypeScheduleFind: function (pharmacyId, locationId, options) {
            return localVarFp.appointmentTypeScheduleFind(pharmacyId, locationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentTypeScheduleFindOne: function (pharmacyId, locationId, id, options) {
            return localVarFp.appointmentTypeScheduleFindOne(pharmacyId, locationId, id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentTypeScheduleRemove: function (pharmacyId, locationId, id, options) {
            return localVarFp.appointmentTypeScheduleRemove(pharmacyId, locationId, id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} id
         * @param {UpdateAppointmentTypeScheduleDto} updateAppointmentTypeScheduleDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentTypeScheduleUpdate: function (pharmacyId, locationId, id, updateAppointmentTypeScheduleDto, options) {
            return localVarFp.appointmentTypeScheduleUpdate(pharmacyId, locationId, id, updateAppointmentTypeScheduleDto, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.ServiceScheduleApiFactory = ServiceScheduleApiFactory;
/**
 * ServiceScheduleApi - object-oriented interface
 * @export
 * @class ServiceScheduleApi
 * @extends {BaseAPI}
 */
var ServiceScheduleApi = /** @class */ (function (_super) {
    __extends(ServiceScheduleApi, _super);
    function ServiceScheduleApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {string} pharmacyId
     * @param {string} locationId
     * @param {CreateAppointmentTypeScheduleDto} createAppointmentTypeScheduleDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceScheduleApi
     */
    ServiceScheduleApi.prototype.appointmentTypeScheduleCreate = function (pharmacyId, locationId, createAppointmentTypeScheduleDto, options) {
        var _this = this;
        return (0, exports.ServiceScheduleApiFp)(this.configuration).appointmentTypeScheduleCreate(pharmacyId, locationId, createAppointmentTypeScheduleDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {string} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceScheduleApi
     */
    ServiceScheduleApi.prototype.appointmentTypeScheduleFind = function (pharmacyId, locationId, options) {
        var _this = this;
        return (0, exports.ServiceScheduleApiFp)(this.configuration).appointmentTypeScheduleFind(pharmacyId, locationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {string} locationId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceScheduleApi
     */
    ServiceScheduleApi.prototype.appointmentTypeScheduleFindOne = function (pharmacyId, locationId, id, options) {
        var _this = this;
        return (0, exports.ServiceScheduleApiFp)(this.configuration).appointmentTypeScheduleFindOne(pharmacyId, locationId, id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {string} locationId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceScheduleApi
     */
    ServiceScheduleApi.prototype.appointmentTypeScheduleRemove = function (pharmacyId, locationId, id, options) {
        var _this = this;
        return (0, exports.ServiceScheduleApiFp)(this.configuration).appointmentTypeScheduleRemove(pharmacyId, locationId, id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {string} locationId
     * @param {string} id
     * @param {UpdateAppointmentTypeScheduleDto} updateAppointmentTypeScheduleDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceScheduleApi
     */
    ServiceScheduleApi.prototype.appointmentTypeScheduleUpdate = function (pharmacyId, locationId, id, updateAppointmentTypeScheduleDto, options) {
        var _this = this;
        return (0, exports.ServiceScheduleApiFp)(this.configuration).appointmentTypeScheduleUpdate(pharmacyId, locationId, id, updateAppointmentTypeScheduleDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ServiceScheduleApi;
}(base_1.BaseAPI));
exports.ServiceScheduleApi = ServiceScheduleApi;
/**
 * ServiceScheduleAvailabilityApi - axios parameter creator
 * @export
 */
var ServiceScheduleAvailabilityApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} scheduleId
         * @param {CreateScheduleAvailabilityDto} createScheduleAvailabilityDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleAvailabilityCreate: function (pharmacyId, locationId, scheduleId, createScheduleAvailabilityDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('scheduleAvailabilityCreate', 'pharmacyId', pharmacyId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('scheduleAvailabilityCreate', 'locationId', locationId);
                    // verify required parameter 'scheduleId' is not null or undefined
                    (0, common_1.assertParamExists)('scheduleAvailabilityCreate', 'scheduleId', scheduleId);
                    // verify required parameter 'createScheduleAvailabilityDto' is not null or undefined
                    (0, common_1.assertParamExists)('scheduleAvailabilityCreate', 'createScheduleAvailabilityDto', createScheduleAvailabilityDto);
                    localVarPath = "/pharmacies/{pharmacy_id}/locations/{location_id}/appointment-type-schedules/{schedule_id}/availabilities"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)))
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("schedule_id", "}"), encodeURIComponent(String(scheduleId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createScheduleAvailabilityDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} scheduleId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleAvailabilityFind: function (pharmacyId, locationId, scheduleId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('scheduleAvailabilityFind', 'pharmacyId', pharmacyId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('scheduleAvailabilityFind', 'locationId', locationId);
                    // verify required parameter 'scheduleId' is not null or undefined
                    (0, common_1.assertParamExists)('scheduleAvailabilityFind', 'scheduleId', scheduleId);
                    localVarPath = "/pharmacies/{pharmacy_id}/locations/{location_id}/appointment-type-schedules/{schedule_id}/availabilities"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)))
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("schedule_id", "}"), encodeURIComponent(String(scheduleId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} scheduleId
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleAvailabilityRemove: function (pharmacyId, locationId, scheduleId, id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('scheduleAvailabilityRemove', 'pharmacyId', pharmacyId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('scheduleAvailabilityRemove', 'locationId', locationId);
                    // verify required parameter 'scheduleId' is not null or undefined
                    (0, common_1.assertParamExists)('scheduleAvailabilityRemove', 'scheduleId', scheduleId);
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('scheduleAvailabilityRemove', 'id', id);
                    localVarPath = "/pharmacies/{pharmacy_id}/locations/{location_id}/appointment-type-schedules/{schedule_id}/availabilities/{id}"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)))
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("schedule_id", "}"), encodeURIComponent(String(scheduleId)))
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} scheduleId
         * @param {number} id
         * @param {UpdateScheduleAvailabilityDto} updateScheduleAvailabilityDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleAvailabilityUpdate: function (pharmacyId, locationId, scheduleId, id, updateScheduleAvailabilityDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('scheduleAvailabilityUpdate', 'pharmacyId', pharmacyId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('scheduleAvailabilityUpdate', 'locationId', locationId);
                    // verify required parameter 'scheduleId' is not null or undefined
                    (0, common_1.assertParamExists)('scheduleAvailabilityUpdate', 'scheduleId', scheduleId);
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('scheduleAvailabilityUpdate', 'id', id);
                    // verify required parameter 'updateScheduleAvailabilityDto' is not null or undefined
                    (0, common_1.assertParamExists)('scheduleAvailabilityUpdate', 'updateScheduleAvailabilityDto', updateScheduleAvailabilityDto);
                    localVarPath = "/pharmacies/{pharmacy_id}/locations/{location_id}/appointment-type-schedules/{schedule_id}/availabilities/{id}"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)))
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)))
                        .replace("{".concat("schedule_id", "}"), encodeURIComponent(String(scheduleId)))
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updateScheduleAvailabilityDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
exports.ServiceScheduleAvailabilityApiAxiosParamCreator = ServiceScheduleAvailabilityApiAxiosParamCreator;
/**
 * ServiceScheduleAvailabilityApi - functional programming interface
 * @export
 */
var ServiceScheduleAvailabilityApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.ServiceScheduleAvailabilityApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} scheduleId
         * @param {CreateScheduleAvailabilityDto} createScheduleAvailabilityDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleAvailabilityCreate: function (pharmacyId, locationId, scheduleId, createScheduleAvailabilityDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.scheduleAvailabilityCreate(pharmacyId, locationId, scheduleId, createScheduleAvailabilityDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} scheduleId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleAvailabilityFind: function (pharmacyId, locationId, scheduleId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.scheduleAvailabilityFind(pharmacyId, locationId, scheduleId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} scheduleId
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleAvailabilityRemove: function (pharmacyId, locationId, scheduleId, id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.scheduleAvailabilityRemove(pharmacyId, locationId, scheduleId, id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} scheduleId
         * @param {number} id
         * @param {UpdateScheduleAvailabilityDto} updateScheduleAvailabilityDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleAvailabilityUpdate: function (pharmacyId, locationId, scheduleId, id, updateScheduleAvailabilityDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.scheduleAvailabilityUpdate(pharmacyId, locationId, scheduleId, id, updateScheduleAvailabilityDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.ServiceScheduleAvailabilityApiFp = ServiceScheduleAvailabilityApiFp;
/**
 * ServiceScheduleAvailabilityApi - factory interface
 * @export
 */
var ServiceScheduleAvailabilityApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.ServiceScheduleAvailabilityApiFp)(configuration);
    return {
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} scheduleId
         * @param {CreateScheduleAvailabilityDto} createScheduleAvailabilityDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleAvailabilityCreate: function (pharmacyId, locationId, scheduleId, createScheduleAvailabilityDto, options) {
            return localVarFp.scheduleAvailabilityCreate(pharmacyId, locationId, scheduleId, createScheduleAvailabilityDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} scheduleId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleAvailabilityFind: function (pharmacyId, locationId, scheduleId, options) {
            return localVarFp.scheduleAvailabilityFind(pharmacyId, locationId, scheduleId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} scheduleId
         * @param {number} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleAvailabilityRemove: function (pharmacyId, locationId, scheduleId, id, options) {
            return localVarFp.scheduleAvailabilityRemove(pharmacyId, locationId, scheduleId, id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} locationId
         * @param {string} scheduleId
         * @param {number} id
         * @param {UpdateScheduleAvailabilityDto} updateScheduleAvailabilityDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scheduleAvailabilityUpdate: function (pharmacyId, locationId, scheduleId, id, updateScheduleAvailabilityDto, options) {
            return localVarFp.scheduleAvailabilityUpdate(pharmacyId, locationId, scheduleId, id, updateScheduleAvailabilityDto, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.ServiceScheduleAvailabilityApiFactory = ServiceScheduleAvailabilityApiFactory;
/**
 * ServiceScheduleAvailabilityApi - object-oriented interface
 * @export
 * @class ServiceScheduleAvailabilityApi
 * @extends {BaseAPI}
 */
var ServiceScheduleAvailabilityApi = /** @class */ (function (_super) {
    __extends(ServiceScheduleAvailabilityApi, _super);
    function ServiceScheduleAvailabilityApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {string} pharmacyId
     * @param {string} locationId
     * @param {string} scheduleId
     * @param {CreateScheduleAvailabilityDto} createScheduleAvailabilityDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceScheduleAvailabilityApi
     */
    ServiceScheduleAvailabilityApi.prototype.scheduleAvailabilityCreate = function (pharmacyId, locationId, scheduleId, createScheduleAvailabilityDto, options) {
        var _this = this;
        return (0, exports.ServiceScheduleAvailabilityApiFp)(this.configuration).scheduleAvailabilityCreate(pharmacyId, locationId, scheduleId, createScheduleAvailabilityDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {string} locationId
     * @param {string} scheduleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceScheduleAvailabilityApi
     */
    ServiceScheduleAvailabilityApi.prototype.scheduleAvailabilityFind = function (pharmacyId, locationId, scheduleId, options) {
        var _this = this;
        return (0, exports.ServiceScheduleAvailabilityApiFp)(this.configuration).scheduleAvailabilityFind(pharmacyId, locationId, scheduleId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {string} locationId
     * @param {string} scheduleId
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceScheduleAvailabilityApi
     */
    ServiceScheduleAvailabilityApi.prototype.scheduleAvailabilityRemove = function (pharmacyId, locationId, scheduleId, id, options) {
        var _this = this;
        return (0, exports.ServiceScheduleAvailabilityApiFp)(this.configuration).scheduleAvailabilityRemove(pharmacyId, locationId, scheduleId, id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {string} locationId
     * @param {string} scheduleId
     * @param {number} id
     * @param {UpdateScheduleAvailabilityDto} updateScheduleAvailabilityDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceScheduleAvailabilityApi
     */
    ServiceScheduleAvailabilityApi.prototype.scheduleAvailabilityUpdate = function (pharmacyId, locationId, scheduleId, id, updateScheduleAvailabilityDto, options) {
        var _this = this;
        return (0, exports.ServiceScheduleAvailabilityApiFp)(this.configuration).scheduleAvailabilityUpdate(pharmacyId, locationId, scheduleId, id, updateScheduleAvailabilityDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ServiceScheduleAvailabilityApi;
}(base_1.BaseAPI));
exports.ServiceScheduleAvailabilityApi = ServiceScheduleAvailabilityApi;
/**
 * ServiceTypeApi - axios parameter creator
 * @export
 */
var ServiceTypeApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {string} pharmacyId
         * @param {CreateAppointmentGroupDto} createAppointmentGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentGroupCreate: function (pharmacyId, createAppointmentGroupDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentGroupCreate', 'pharmacyId', pharmacyId);
                    // verify required parameter 'createAppointmentGroupDto' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentGroupCreate', 'createAppointmentGroupDto', createAppointmentGroupDto);
                    localVarPath = "/pharmacies/{pharmacy_id}/appointment-groups"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createAppointmentGroupDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentGroupFind: function (pharmacyId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentGroupFind', 'pharmacyId', pharmacyId);
                    localVarPath = "/pharmacies/{pharmacy_id}/appointment-groups"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentGroupFindAvailableLocations: function (pharmacyId, id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentGroupFindAvailableLocations', 'pharmacyId', pharmacyId);
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentGroupFindAvailableLocations', 'id', id);
                    localVarPath = "/pharmacies/{pharmacy_id}/appointment-groups/{id}/available-locations"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)))
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentGroupFindOne: function (pharmacyId, id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentGroupFindOne', 'pharmacyId', pharmacyId);
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentGroupFindOne', 'id', id);
                    localVarPath = "/pharmacies/{pharmacy_id}/appointment-groups/{id}"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)))
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentGroupRemove: function (pharmacyId, id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentGroupRemove', 'pharmacyId', pharmacyId);
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentGroupRemove', 'id', id);
                    localVarPath = "/pharmacies/{pharmacy_id}/appointment-groups/{id}"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)))
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} id
         * @param {UpdateAppointmentGroupDto} updateAppointmentGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentGroupUpdate: function (pharmacyId, id, updateAppointmentGroupDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'pharmacyId' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentGroupUpdate', 'pharmacyId', pharmacyId);
                    // verify required parameter 'id' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentGroupUpdate', 'id', id);
                    // verify required parameter 'updateAppointmentGroupDto' is not null or undefined
                    (0, common_1.assertParamExists)('appointmentGroupUpdate', 'updateAppointmentGroupDto', updateAppointmentGroupDto);
                    localVarPath = "/pharmacies/{pharmacy_id}/appointment-groups/{id}"
                        .replace("{".concat("pharmacy_id", "}"), encodeURIComponent(String(pharmacyId)))
                        .replace("{".concat("id", "}"), encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updateAppointmentGroupDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
exports.ServiceTypeApiAxiosParamCreator = ServiceTypeApiAxiosParamCreator;
/**
 * ServiceTypeApi - functional programming interface
 * @export
 */
var ServiceTypeApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.ServiceTypeApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {string} pharmacyId
         * @param {CreateAppointmentGroupDto} createAppointmentGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentGroupCreate: function (pharmacyId, createAppointmentGroupDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.appointmentGroupCreate(pharmacyId, createAppointmentGroupDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentGroupFind: function (pharmacyId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.appointmentGroupFind(pharmacyId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentGroupFindAvailableLocations: function (pharmacyId, id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.appointmentGroupFindAvailableLocations(pharmacyId, id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentGroupFindOne: function (pharmacyId, id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.appointmentGroupFindOne(pharmacyId, id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentGroupRemove: function (pharmacyId, id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.appointmentGroupRemove(pharmacyId, id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} id
         * @param {UpdateAppointmentGroupDto} updateAppointmentGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentGroupUpdate: function (pharmacyId, id, updateAppointmentGroupDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.appointmentGroupUpdate(pharmacyId, id, updateAppointmentGroupDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.ServiceTypeApiFp = ServiceTypeApiFp;
/**
 * ServiceTypeApi - factory interface
 * @export
 */
var ServiceTypeApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.ServiceTypeApiFp)(configuration);
    return {
        /**
         *
         * @param {string} pharmacyId
         * @param {CreateAppointmentGroupDto} createAppointmentGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentGroupCreate: function (pharmacyId, createAppointmentGroupDto, options) {
            return localVarFp.appointmentGroupCreate(pharmacyId, createAppointmentGroupDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentGroupFind: function (pharmacyId, options) {
            return localVarFp.appointmentGroupFind(pharmacyId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentGroupFindAvailableLocations: function (pharmacyId, id, options) {
            return localVarFp.appointmentGroupFindAvailableLocations(pharmacyId, id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentGroupFindOne: function (pharmacyId, id, options) {
            return localVarFp.appointmentGroupFindOne(pharmacyId, id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentGroupRemove: function (pharmacyId, id, options) {
            return localVarFp.appointmentGroupRemove(pharmacyId, id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} pharmacyId
         * @param {string} id
         * @param {UpdateAppointmentGroupDto} updateAppointmentGroupDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appointmentGroupUpdate: function (pharmacyId, id, updateAppointmentGroupDto, options) {
            return localVarFp.appointmentGroupUpdate(pharmacyId, id, updateAppointmentGroupDto, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.ServiceTypeApiFactory = ServiceTypeApiFactory;
/**
 * ServiceTypeApi - object-oriented interface
 * @export
 * @class ServiceTypeApi
 * @extends {BaseAPI}
 */
var ServiceTypeApi = /** @class */ (function (_super) {
    __extends(ServiceTypeApi, _super);
    function ServiceTypeApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {string} pharmacyId
     * @param {CreateAppointmentGroupDto} createAppointmentGroupDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceTypeApi
     */
    ServiceTypeApi.prototype.appointmentGroupCreate = function (pharmacyId, createAppointmentGroupDto, options) {
        var _this = this;
        return (0, exports.ServiceTypeApiFp)(this.configuration).appointmentGroupCreate(pharmacyId, createAppointmentGroupDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceTypeApi
     */
    ServiceTypeApi.prototype.appointmentGroupFind = function (pharmacyId, options) {
        var _this = this;
        return (0, exports.ServiceTypeApiFp)(this.configuration).appointmentGroupFind(pharmacyId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceTypeApi
     */
    ServiceTypeApi.prototype.appointmentGroupFindAvailableLocations = function (pharmacyId, id, options) {
        var _this = this;
        return (0, exports.ServiceTypeApiFp)(this.configuration).appointmentGroupFindAvailableLocations(pharmacyId, id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceTypeApi
     */
    ServiceTypeApi.prototype.appointmentGroupFindOne = function (pharmacyId, id, options) {
        var _this = this;
        return (0, exports.ServiceTypeApiFp)(this.configuration).appointmentGroupFindOne(pharmacyId, id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceTypeApi
     */
    ServiceTypeApi.prototype.appointmentGroupRemove = function (pharmacyId, id, options) {
        var _this = this;
        return (0, exports.ServiceTypeApiFp)(this.configuration).appointmentGroupRemove(pharmacyId, id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} pharmacyId
     * @param {string} id
     * @param {UpdateAppointmentGroupDto} updateAppointmentGroupDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceTypeApi
     */
    ServiceTypeApi.prototype.appointmentGroupUpdate = function (pharmacyId, id, updateAppointmentGroupDto, options) {
        var _this = this;
        return (0, exports.ServiceTypeApiFp)(this.configuration).appointmentGroupUpdate(pharmacyId, id, updateAppointmentGroupDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ServiceTypeApi;
}(base_1.BaseAPI));
exports.ServiceTypeApi = ServiceTypeApi;
